/* .PdfAnnotator {
  position: fixed;
  overflow: auto;
  width: 140vh;
  height: 89vh;
} */

.PdfAnnotator {
  position: absolute;
  overflow: auto;
  width: 100%;
  height: inherit;  /* rest is space for editor */
  margin: 0 auto;
  top: 0;
  left: 0;
}

.pdfViewer {
  margin-top: 64px;  /* this takes into account our header */
}

.PdfAnnotator__highlight-layer {
  position: absolute;
  z-index: 3;
  left: 0;
}

.PdfAnnotator__tip-container {
  z-index: 6;
  position: absolute;
}

.PdfAnnotator--disable-selection {
  user-select: none;
  pointer-events: none;
}
/* overrides for pdf_viewer.css from PDF.JS web viewer */

.textLayer {
  z-index: 2;
  opacity: 1;
}

.forceTextRender .textLayer > div {
  color: black !important;
}

/* we actually dont want this since it fscks with highlighting */ 
.xannotationLayer {
  position: absolute;
  top: 0;

  z-index: 3;
}

.highlight-layer {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  overflow:hidden;
  line-height:1.0;
  z-index: 1;
  /*opacity: 0.3;*/
}

html
  body
  .textLayer
  > div:not(.PdfAnnotator__highlight-layer):not(.Highlight):not(.Highlight-emoji) {
  opacity: 0.85;
}

.textLayer ::selection {
  background: rgba(63, 81, 181, 0.7);
}


.highlight_part {
  position: absolute;
  border-radius: 3px;
  user-select: none;
  font-size: 12px;
  color: transparent;
  padding: 3px 5px 0 5px;
  z-index: 1;
}
.highlight_part:hover {
  position: absolute;
  color: #333;
  z-index: 10;
}
.highlight_part span {
  position: absolute;
  color: #333;
  bottom: 130%;
  left: 90%;
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 3px;
}


.highlight_section {
  border: 1px solid rgba(51, 102, 153, .5);
  background-color: rgba(51, 102, 153, .3);
}
.highlight_brand {
  border: 1px solid rgba(79, 195, 247, .5);
  background-color: rgba(79, 195, 247, .3);
  z-index: 1;
}
/*.highlight_brand:hover {*/
/*  background-color: rgba(79, 195, 247, 1);*/
/*}*/
.highlight_brand-synonym {
  /*border: 1px solid rgba(129, 199, 132, .5);*/
  /*background-color: rgba(129, 199, 132, .3);*/
  z-index: 2;
}
/*.highlight_brand-synonym:hover {*/
/*  background-color: rgba(129, 199, 132, 1);*/
/*}*/
.highlight_brand-synonym--bad {
  border: 1px solid rgba(229, 115, 155, .5);
  background-color: rgba(229, 115, 155, .3);
  z-index: 3;
}
/*.highlight_brand-synonym--bad:hover {*/
/*  background-color: rgba(229, 115, 155, 1);*/
/*}*/
.highlight_brand-synonym--bad span {
  background-color: rgba(229, 115, 155, 1);
}
.highlight_brand-synonym--good {
  border: 1px solid rgba(255, 183, 77, .5);
  background-color: rgba(255, 183, 77, .3);
  z-index: 4;
}
/*.highlight_brand-synonym--good:hover {*/
/*  background-color: rgba(255, 183, 77, 1);*/
/*}*/
.highlight_brand-synonym--good span {
  background-color: rgba(255, 183, 77, 1);
}
.highlight_brand-synonym--best {
  border: 1px solid rgba(129, 199, 132, .5);
  background-color: rgba(129, 199, 132, .3);
  z-index: 5;
}
/*.highlight_brand-synonym--best:hover {*/
/*  background-color: rgba(129, 199, 132, 1);*/
/*}*/
.highlight_brand-synonym--best span {
  background-color: rgba(129, 199, 132, 1);
}
.highlight_part.focused {
  z-index: 10;
}
.highlight_part.focused:before {
  background: transparent url("pin.png") center center no-repeat;
  background-size: 24px 24px;
  position: absolute;
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

.hideAllTags .highlight-layer {
  display: none;
}
.hideBadTags .highlight_brand-synonym--bad {
  display: none;
}
.hideGoodTags .highlight_brand-synonym--good {
  display: none;
}
.hideBestTags .highlight_brand-synonym--best {
  display: none;
}

.hideTagsScores .highlight_part span {
  display: none;
}

.highlight_product {
  background-color: rgba(0, 153, 153, .3);
}

.highlight_materialtitle {
  background-color: rgba(34, 69, 104, .3);
}

.highlight_materialbrand {
  background-color: rgba(32, 100, 5, .3);
}

.highlight_materialproduct {
  background-color: rgba(0, 92, 92, .5);
}

