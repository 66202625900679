body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #333;
  color: #fff;
}

a {
  color: #fff;
}

/* .sidebar {
  color: #fff;
  background-color: #333;
  position: relative;
  overflow: auto;
  padding: 0 5px;
  width: 20%;
  height: 92vh;
  background: #333;
}
.sidebar ul {
  list-style: none;
  padding:0;
}
.sidebar ul li {
  padding: 10px 0;
  cursor: pointer;
} */

/* 
.header {
  background-color: #333;
  text-align:right;
}
.header table { 
  display: inline-block;
}
.header a { color: #333; }

.header .title {
  font-size: 18px;
  float:left;
}
.header .login {
  font-size: 12px;
  float:right;
  margin: 1.5vh;
} */

/* .newproject {
  width: 50%;
  margin: 0 auto;
  background-color: #333;
  margin-bottom: 100px;
}

.newproject h1 {
  padding: 20px;
}
.newproject form {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.newproject form input {
  font-size: 16px;
  padding: 10px;
  margin:10px;
} */

.projects, .accounts {
  width: 95%;
  margin: 0 auto;
  position: relative;
}
.projects table {
  width: 100%;
}

.dzone {
  position: relative;
  width: 100%;
  height: 200px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
}

.dzoneactive {
  background-color: rgb(3, 110, 9);
  border-color: rgb(3, 110, 9);
}